import type { LucideIcon } from 'lucide-react';
import { ExternalLink } from 'lucide-react';

type ProductCardProps = {
  icon: LucideIcon;
  title: string;
  description: string;
  url: string;
};

export function ProductCard(props: ProductCardProps) {
  const { icon: Ic, title, description, url } = props;

  return (
    <a
      href={url}
      className="group relative rounded-xl border p-5 transition-colors duration-200 ease-in-out hover:cursor-pointer hover:border-black/30 hover:bg-gray-50/70 hover:shadow-sm"
    >
      <ExternalLink className="absolute right-3 top-3 h-5 text-gray-300 transition group-hover:text-gray-700" />
      <div className="mb-5 flex h-9 w-9 items-center justify-center rounded-full bg-gray-900 text-white">
        <Ic className="h-5" strokeWidth={1.75} />
      </div>
      <h3 className="mb-0.5 text-xl font-semibold sm:mb-2">{title}</h3>
      <p className="text-base text-gray-500">{description}</p>
    </a>
  );
}
